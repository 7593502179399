

%contentHeaderTitle {
  @extend %fontContentHeaderTitle;
  margin-bottom: 20px;
}

%defaultPreview {
  //box-shadow: 2px 2px 9px 0px #8888884f;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .changeImage {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin-top: 42%;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    text-align: center;
    display: none;

    span {
      align-self: center;
      margin: 0 auto;
    }
  }
  .removeImage {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.71);
    display: none;
    align-items: center;
    justify-content: center;
  }

  &:hover .changeImage {
    display: flex;
  }
  &:hover .removeImage {
    display: flex;
  }
}

%defaultPagination {
  background-color: #fafafa;
  height: 52px;
  padding-left: 5px;
  border-top: 1px solid #dff2f4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  .selectPagination {
    float: left;
    margin-top: 22px;
    width: 130px;
    max-width: 130px;
    font-size: 12px;
    color: #323136;
  }
  .total{
    padding: 15px 2px 2px 2px;
    width: 120px;
  }
  .divPagination {
    float: right;
    width: 50%;
  }
}

.md-menu-content {
  z-index: 10000;
}

%defaultTables {
  margin-left: 15px;
  margin-top: 20px;
  margin-right: 15px;
  box-shadow: none;
  .buttonAdd {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.54);
    height: 40px;
    width: 64px;
  }
  .dataTable {
    border-radius: 0px !important;
    header {
      background-color: #00a1b2 !important;
      div:first-of-type {
        padding: 0px;
      }
      .search {
        // width: 267px;
        height: 40px;
        background-color: #00718b;
        opacity: 0.74;
        border-radius: 4px;
        border: solid 1px #006983;
        input, input::placeholder {
          color: #ffffff;
          padding-left: 10px;
        }
        label {
          color: #ffffff;
          left: 10px !important;
          top: 11px;
        }
        div:first-of-type {
          height: 40px;
        }
        div {
          div:before {
            border: none !important;
          }
        }
        i {
          top: -3px;
          color: #ffffff !important;
        }
      }
    }
    header + div {
      background-color: #00a1b2;
    }
    table {
      text-align: center;
      overflow: hidden;
      border-collapse: separate;
      -webkit-border-top-left-radius: 4px;
      -moz-border-top-left-radius: 4px;
      border-top-left-radius: 4px;
      -webkit-border-top-right-radius: 4px;
      -moz-border-top-right-radius: 4px;
      border-top-right-radius: 4px;
      thead {
        tr {
          height: 64px;
          background-color: #fafafa;
          th {
            font-size: 12.9px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3a3a3c !important;
          }
        }
      }
      tbody {
        tr {
          //height: 80px;
          background-color: #ffffff;
          td {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: -0.5px;
            color: #323136;
            .buttonEdit, .buttonDelete {
              i {
                font-size: 16px;
              }
            }
            .buttonEdit {
              margin: 5px;
              padding: 5px;
              height: 28px;
              width: 28px;
            }
            .buttonEye {
              margin: 5px;
              padding: 5px;
              height: 28px;
              width: 28px;
            }
            .buttonDelete {
              margin: 5px;
              padding: 5px;
              height: 28px;
              width: 28px;
            }
          }
        }
      }
    }
  }
}


%defaultBoxF1 {
  overflow-y: hidden;
  //background-color: #00a1b2;

  .container {
    margin: 10px 8px 30px 8px;
    background-color: #00a1b2;

    .content {
      margin-left: 30px !important;
      margin-right: 30px !important;
      padding-right: 30px;
      background-color: #ffffff;
      //box-shadow: 2px 2px 9px 0px #8888884f;
      min-width: 97%;
      //max-width: 1000px;
      //margin: 0 auto;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
      @media screen and (max-width: 600px) {
        min-width: 100%;
      }
      text-align: left;
      padding: 49px;
      .gifProgress{
        text-align: center;
        margin-top: 10px;
      }

      .contentLoaded {
        .contentHeaderTitle {
          @extend %contentHeaderTitle;
        }
        .imagePreview {
          @extend %defaultPreview;
        }
        .fcmEditor {
          border: solid 1px rgba(0, 0, 0, 0.08);
        }
        .body {
          font-family: $font-input;
          .buttonGroup {
            margin-bottom: 20px;
          }
          .pLabel {
            margin-bottom: 0px;
          }
          .averageServiceTime {
            width: 450px;
          }
          .serviceHour {
            display: flex;
            width: 250px;
            .divUntil {
              padding-top: 22px;
              margin-left: 20px;
              margin-right: 20px;
            }
          }
        }
        .error {
          margin-top: 20px;
          margin-bottom: 20px;
          color: $color-input-error;
        }
        .progressBar {
          margin-bottom: 20px;
        }
        .actions {
          margin-top: 35px;
          padding-bottom: 25px;
          .leftButton {
            font-family: $font-primary-text;
            background-color: #00a1b2;
            float: left;
          }
          .leftButton[disabled] {
            background-color: #0000001f;
          }
          .rightButton {
            font-family: $font-primary-text;
            float: right;
            background-color: #1867c0;
          }
        }
      }
    }
  }
}

%defaultBoxF2 {
  overflow-y: hidden;

  .container {

    .content {
      box-shadow: 2px 2px 9px 0px #8888884f;
      min-width: 100%;
      //max-width: 1000px;
      @media screen and (max-width: 1000px) {
        max-width: 100%;
      }
      @media screen and (max-width: 600px) {
        min-width: 100%;
      }
      text-align: left;

      .gifProgress{
        text-align: center;
        margin-top: 10px;
      }

      .contentLoaded {
        .contentHeaderTitle {
          @extend %contentHeaderTitle;
        }
        .imagePreview {
          @extend %defaultPreview;
        }
        .fcmEditor {
          border: solid 1px rgba(0, 0, 0, 0.08);
        }
        .body {
          font-family: $font-input;
        }
        .error {
          margin-top: 20px;
          margin-bottom: 20px;
          color: $color-input-error;
        }
        .progressBar {
          margin-bottom: 20px;
        }
        .actions {
          margin-top: 35px;
          padding-bottom: 25px;
          .leftButton {
            font-family: $font-primary-text;
            background-color: #d46f6d;
            float: left;
          }
          .leftButton[disabled] {
            background-color: #0000001f;
          }
          .rightButton {
            font-family: $font-primary-text;
            float: right;
          }
        }
      }
    }
  }
}

%defaultAlert {
/*  color: white;
  background-color: #da312e80; */

  color: #da312e;
  background-color: white;

  //border: solid 2px #da312ecc;
  z-index: 1000;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
}


%defaultItem
{
    /*font-family: Poppins;*/
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-left: 320px;
    margin-top: 20px;
    color: #ffffff;

    .icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      color: #ffffff;
    }

    @media only screen and (max-width: 1051px) {
      margin-left: 20px;
      margin-top: 13px;
    }
}

%back {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff !important;
  text-align: left;
  cursor: pointer;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 90px;;
  i {
    color: #ffffff !important;
  }
}
