
@import '@design';

.button {
  margin: 0;
  padding-left: 44px;
  padding-right: 44px;
}


