
@import '@design';

.box {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
}

//.navbar {
//  flex: 0 0 auto;
//}

.container {
  /*margin: 0 auto;*/
  flex: 1 0 auto;
}
//
//.footer {
//  flex-shrink: 0;
//}


