// CONTENT
$color-primary: #00a1b2;
$color-body-bg: #00a1b2;
$color-text: #2b3342;
$color-heading-text: #2b3342;

// LINKS
$color-link-text: #ffffff;
$color-link-text-active: $color-text;

// INPUTS
$color-input-border: lighten($color-heading-text, 50%);

// BUTTONS
$color-button-bg: $color-link-text;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;


// FCM

$color-footer-bg: #1f2633;
$color-footer-hr: #2b3342;
$color-footer-copyright: #7691c2;
$color-footer-link: #ffffff;

$color-form-bg: white;
$color-input-selected: #2b3341;
$color-input-error: #cf312e;
$color-input-button-primary: #00a1b2;

$color-chamada-txt: white;

$color-menu-active: #07338c;
$color-menu-inactive: #A9AAA9;
$color-multiselect-tag-bg: #07338c;
$color-multiselect-tag-color: white;


$color-disabled: #a9aaa9;

// Company

$color-company-bg: #ffffff;

$color-submenu-bg: #07338c;
$color-submenu-color: #ffffff;

$color-bg-white: #ffffff;
$color-bg-mid: #4dc8f4;
$color-bg-dark: #263344;

// Admin

$color-bg-navbar: #00718b;
$color-bg: #00a1b2;
$color-bg-footer-left: #006983;

