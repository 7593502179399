$font-primary-text: Montserrat, sans-serif;
$font-secondary-text: Roboto, sans-serif;
$font-input: Roboto, sans-serif;

$system-default-font-family: $font-primary-text;

$heading-font-family: $system-default-font-family;
$heading-font-weight: 600;

$content-font-family: $system-default-font-family;
$content-font-weight: 400;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-heading-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-content {
  font-family: $content-font-family;
  font-weight: $content-font-weight;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


%fontContentHeaderTitle {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
}



%font-navbar {
  font-family: $font-secondary-text;
  font-weight: 500;
  font-size: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
